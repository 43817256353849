import React from "react";
import { Box, IconButton } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";

function SocialMediaBar() {
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: "50%",
        right: {xs:"2px",sm:"6px",md:"20px"},
        zIndex: "100",
        transform: "translate(0, 50%)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "0.125rem",
            height: "2.2rem",
            backgroundColor: "black",
            boxShadow: "0px 0px 15px 0px black",
          }}
        ></Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          mb: "0.2rem",
        }}
      >
        <IconButton>
          <FacebookIcon sx={{ color: "black" }} 
          onClick={() => window.open("https://www.facebook.com/educationdotcom", "_blank")}/>
        </IconButton>
        <IconButton>
          <InstagramIcon sx={{ color: "black" }} 
          onClick={() => window.open("https://www.instagram.com/education.com2009/", "_blank")} />
        </IconButton>
        <IconButton>
          <YouTubeIcon sx={{ color: "black" }} 
          onClick={() => window.open("https://youtube.com/@eductioncom?si=_Mm-lnFrvdMwKVqa", "_blank")} />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: "0.2rem",
        }}
      >
        <Box
          sx={{
            width: "0.125rem",
            height: "2.2rem",
            backgroundColor: "black",
            boxShadow: "0px 0px 15px 0px black",
          }}
        ></Box>
      </Box>
    </Box>
  );
}

export default SocialMediaBar;
