import React, { useState, useEffect, useMemo } from 'react'; 
import { Typography, Card, CardContent, Button, Dialog, DialogTitle, DialogContent, DialogActions, Container } from '@mui/material'; // Import Dialog components from MUI
import Grid from '@mui/material/Grid'
import apiClient from '../../config/axiosConfig';
import Logo from '../../assets/eduLogo.png';

function OurServices({ serviceRequested }) {
   const [selectedService, setSelectedService] = useState(null); // Initialize state for selected service
   const [services, setServices] = useState([]);
   const [fetchServices, setFetchServices] = useState([]);

   useEffect(() => {
      const fetchService = () => {
         apiClient.get('/services').then((response) => {
            setFetchServices(response.data.servicesDetail);
         }).catch((error) => {
            console.log(error);
         });
      };
      fetchService();
   }, []);

   const handleDialogOpen = (service) => {
     setSelectedService(service); // Set selected service when Learn More button is clicked
   };

   const handleDialogClose = () => {
     setSelectedService(null); // Close the dialog
   };

   // Filter services based on serviceRequested
   const studyInNepalServices = useMemo(
      () => fetchServices.filter((service) => service.whichCourse === 'Study In Nepal'),
      [fetchServices]
   );

   const studyAbroadServices = useMemo(
      () => fetchServices.filter((service) => service.whichCourse === 'Study Abroad'),
      [fetchServices]
   );

   useEffect(() => {
      if (serviceRequested === 'Study In Nepal') {
         setServices(studyInNepalServices);
      } else {
         setServices(studyAbroadServices);
      }
   }, [serviceRequested, studyInNepalServices, studyAbroadServices]);

   return (
      <Container maxWidth={false} sx={{ padding: '3rem 0px', backgroundColor: '#eaf2f8' }}>
         <Container>
            <Typography
               variant="h4"
               align="center"
               gutterBottom
               sx={{
                  fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
                  fontWeight: 'bold',
                  color: '#003366',
                  textTransform: 'uppercase',
                  marginBottom: '2.2rem',
                  fontFamily: "Roboto-Serif"
               }}
            >
               Our Services
            </Typography>

            {/* Grid Layout for Services */}
            <Grid container spacing={3} justifyContent="center">
               {services.map((service) => (
                  <Grid item xs={12} sm={6} md={4} key={service.id}>
                     <Card
                        variant="outlined"
                        sx={{
                           display: 'flex',
                           flexDirection: 'column',
                           justifyContent: 'space-between',
                           textAlign: 'center',
                           boxShadow: 3,
                           transition: 'transform 0.3s',
                           width: {xs:'88%',sm:'95%',md:'100%'},
                           height: 'auto', 
                           maxWidth: 300,
                           margin: '0 auto',
                           '&:hover': {
                              transform: 'scale(1.05)',
                              boxShadow: 6,
                           },
                        }}
                     >
                        <CardContent sx={{ padding: 2 }}>
                           <img src={Logo} height="70px" width="90px" alt='service-icon' />
                           <Typography variant="h6" component="div" sx={{ marginTop: 1,
                                fontWeight: 'bold',
                                fontSize: { xs: '14px', sm: '16px', md: '20px' },
                                fontFamily: 'Roboto-Serif',
                                }}>
                              {service.sTitle}
                           </Typography>
                           <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 1, fontFamily: "Poppins" }}>
                              {service.sDetail.split(" ").slice(0, 10).join(" ")}{service.sDetail.split(" ").length > 10 ? "..." : ""}
                           </Typography>
                           <Button
                              variant="contained"
                              sx={{
                                 backgroundColor: '#49BBBD',
                                 fontFamily: "Poppins",
                                 '&:hover': { backgroundColor: '#ff5722' },
                                 color: 'white',
                              }}
                              onClick={() => handleDialogOpen(service)} // Open dialog when clicked
                           >
                              Learn More
                           </Button>
                        </CardContent>
                     </Card>
                  </Grid>
               ))}
            </Grid>

            {/* Dialog for showing service details */}
            {selectedService && (
               <Dialog open={Boolean(selectedService)} onClose={handleDialogClose}>
                  <DialogTitle sx={{ color: '#003366', fontSize: { xs: '1.2rem', sm: '1.6rem' } }}>
                     {selectedService.sTitle}
                  </DialogTitle>
                  <DialogContent>
                     <Typography sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}>
                        {selectedService.sDetail}
                     </Typography>
                  </DialogContent>
                  <DialogActions>
                     <Button
                        onClick={handleDialogClose}
                        variant="contained"
                        sx={{
                           backgroundColor: '#ff9800',
                           '&:hover': { backgroundColor: '#ff5722' },
                           color: 'white',
                        }}
                     >
                        Learn Less
                     </Button>
                  </DialogActions>
               </Dialog>
            )}
         </Container>
      </Container>
   );
}

export default OurServices;
