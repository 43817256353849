
import React, { useEffect, useMemo, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import './App.css';

import { ToastContainer, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import About from './component/About/About';
import Contact from './component/Contact/Contact';
import StudyAbroad from './component/AbroadStudy/StudyAbroad';
import Navbar from './layout/nav_bar/nav_bar_main';
import StudyInNepal from './component/StudyNepal/StudyNepal.jsx';
import BachelorPrograms from './component/StudyNepal/ManagementItPrograms';
import AlumniStudentWithRank from './component/AliminiStudents/AluminiStudentWithRank';
import Firstpage from './component/pages/firstpage';

import DestinedCountry from './component/AbroadStudy/Country/destinedCountry.jsx';
import CountryDetail from './component/AbroadStudy/Country/countryDetail';
import AllNews from './component/section/newsSection.jsx';
import AllNotices from './component/section/noticeSection.jsx';

import NepaliCoursesDescriptionPage from './component/pages/nepaliCoursesDescription.page';
import AbroadCoursesDescriptionPage from './component/pages/abroadCoursesDescription.page';
import apiClient from './config/axiosConfig';

const App = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const showNavbar = location.pathname !== '/';
    const [students, setStudents] = useState([]);
    const [abroadCourses, setAbroadCourses] = useState([]);
    const [nepaliCourses, setNepaliCourses] = useState([]);

    useEffect(() => {
        const fetchStudentsRecord = () => {
            apiClient.get('/register').then((response) => {
                setStudents(response.data.StudentDetail);
            }).catch((error) => {
                console.log(error);
            });
        };
        // For Nepali courses
        const fetchNepaliCourses = () => {
            apiClient.get(`/studynepalicourses`).then((response) => {
                setNepaliCourses(response.data.studyNepalCourses);
            }).catch((error) => {
                console.log(error);
            });
        };
        // For Abroad courses
        const fetchAbroadCourses = () => {
            apiClient.get(`/abroadcourses`).then((response) => {
                setAbroadCourses(response.data.AbroadCourse);
            }).catch((error) => {
                console.log(error);
            });
        };

        fetchAbroadCourses();
        fetchNepaliCourses();
        fetchStudentsRecord();
    }, []);

    const studyInNepalStudent = useMemo(
        () => students.filter((student) => student.destination === 'Study Nepal' && student.feedback?.trim()),
        [students]
    );
    const studyAbroadStudent = useMemo(
        () => students.filter((student) => student.destination === 'Study Abroad' && student.feedback?.trim()),
        [students]
    );

    const abroadPreparationTests = useMemo(() => {
        if (abroadCourses.length > 0) {
            const extractedTitles = abroadCourses.map(course =>
                course.courseTitle.split('(')[0].trim()
            );
            return [...new Set(extractedTitles)];
        }
        return [];
    }, [abroadCourses]);

    const nepaliCoursePreparationTests = useMemo(() => {
        if (nepaliCourses.length > 0) {
            const extractedTitles = nepaliCourses.map(course =>
                course.courseTitle.split('(')[0].trim()
            );
            return [...new Set(extractedTitles)];
        }
        return [];
    }, [nepaliCourses]);

    const mergeCourses = nepaliCoursePreparationTests.concat(abroadPreparationTests);

    return (
        <>
            {showNavbar && <Navbar prepCourses={mergeCourses} abroadCourses={abroadCourses} nepaliCourses={nepaliCourses} />}
            <Routes>
                <Route path="/" element={<Firstpage onNavigate={() => navigate('/home')} />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/study-abroad" element={<StudyAbroad studentTestimonial={studyAbroadStudent} abroadCourses={abroadCourses} />} />
                <Route path="/study-in-nepal" element={<StudyInNepal studentTestimonial={studyInNepalStudent} nepaliCourses={nepaliCourses} />} />
                <Route path="/bachelor-programs" element={<BachelorPrograms />} />
                <Route path="/alumni" element={<AlumniStudentWithRank studentRecord={students} />} />
                <Route path="/destination-country" element={<DestinedCountry />} />
                <Route path="/:countryId/:id" element={<CountryDetail />} />
                <Route path="/desc" element={<NepaliCoursesDescriptionPage />} />
                <Route path="/abroad-course-desc" element={<AbroadCoursesDescriptionPage />} />
                <Route path="/news" element={<AllNews />} />
                <Route path="/notices" element={<AllNotices />} />
            </Routes>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Flip}
            />
        </>
    );
};

const MainApp = () => (
    <Router>
        <App />
    </Router>
);

export default MainApp;
