import * as React from 'react';
import { Card, CardContent, CardMedia, Typography, CardActionArea } from '@mui/material';

// Accept props for dynamic content
export default function ActionAreaCard({ image, title, description, onClick }) {
  return (
    <Card sx={{
      width: {xs:'80%',sm:'85%',md:'90%',lg:'87%'}, // Make the card fill the available space within the flex container
      // height: 300,   // Fixed height for consistency
      // position: 'relative',
      overflow: 'hidden',
      marginLeft:{xs:'4px'},
      display: 'flex',
      // backgroundColor:'orangered',
      flexDirection: 'column',
      marginLeft:{xs:'1rem'},
      boxShadow: 3,  // Added shadow to make cards stand out
    }}>
      <CardActionArea onClick={onClick}>
        <CardMedia
          component="img"
          sx={{
            height: '260px',   // Set fixed height for both image and video thumbnails
            width: '100%',
            // backgroundColor:'skyblue',
            objectFit: 'cover',
          }}
          image={image} // Dynamically set the image
          alt={title}   // Dynamically set the alt text
        />
        <CardContent sx={{
          position: 'relative',
          bottom: 0,
          left: 0,
          right: 0,
          // backgroundColor:'purple',
          // bgcolor: 'rgba(84, 75, 99, 0.4)',
          // color: 'white',
          padding: '10px',
          fontSize: '14px',
        }}>
          <Typography gutterBottom variant="h6" component="div"
                     sx={{ 
                        fontWeight: 'bold',
                        // backgroundColor:'green',
                          fontSize: { xs: '12px', sm: '14px', md: '16px' },
                        fontFamily: 'Poppins',}}
                        >
            {title}  {/* Dynamically set the title */}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {description}  {/* Dynamically set the description */}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}











// import React from "react";
// import { Box, Typography, CardContent, CardMedia, Button } from "@mui/material";
// import { useNavigate } from "react-router-dom";
// import PropTypes from "prop-types";

// // Function to convert buffer to Base64
// const bufferToBase64 = (buffer) => {
//   let binary = "";
//   const bytes = new Uint8Array(buffer);
//   const len = bytes.byteLength;
//   for (let i = 0; i < len; i++) {
//     binary += String.fromCharCode(bytes[i]);
//   }
//   return window.btoa(binary);
// };

// function FoodCardContent({
//   fooditem,
//   subcategory,
//   handleAddToCart,
//   item_image,
//   item_price
// }) {
//   const navigate = useNavigate();
  

//   const base64Image = item_image
//   ? `data:image/png;base64,${bufferToBase64(item_image.data)}`
//   : "/path/to/default/image.jpg";
  
//   const handleDetailsClick = () => {
//     navigate("/description", {
//       state: {
//         foodItem: {
//           ...fooditem,
//           image: base64Image, // Pass the base64 image string
//         },
//         subcategory,
//       },
//     });
//   };
 
//   const getCurrentPrice = () => {
//     // Get current hour
//     const hour = new Date().getHours();

//     // Determine if it's day or night
//     const isDay = hour >= 6 && hour < 18; // Example: Day is from 6 AM to 6 PM

//     // Parse item_price
//     let prices = [];
//     try {
//       if (fooditem.item_price) {
//         prices = JSON.parse(fooditem.item_price);
//       }
//     } catch (e) {
//       console.error("Error parsing item_price:", e);
//     }

//     // Return the appropriate price
//     if (prices.length > 1) {
//       return isDay ? prices[0] : prices[1];
//     } else if (prices.length === 1) {
//       return prices[0];
//     } else {
//       return "Price not available";
//     }
//   };

//   const price = getCurrentPrice();
  
//   return (
//     <CardContent>
//       <CardMedia
//         component="img"
//         image={base64Image}
//         style={{
//           width: "15rem",
//           height: "10rem",
//           borderRadius: "50%",
//           margin: "1.5rem auto",
//           padding: "1rem",
//         }}
//       />
//       <Typography
//         className="responsive_fontsize18"
//         variant="subtitle1"
//         sx={{ my: 0.3, textAlign: "center" }}
//       >
//         {subcategory}
//       </Typography>
//       <Box >
//         <span
//           style={{
//             padding: "8px",
//             position: "absolute",
//             right: "0",
//             top: "0",
//             backgroundColor: "white",
//             borderRadius: "4px",
//           }}
//         >
//           <Typography
//             className="responsive_fontsize14"
//             sx={{ textAlign: "center", color: "#A76C00" }}
//           >
//             Rs {price}
//           </Typography>
//         </span>
//         <Box
//           sx={{
//             display: "flex",
//             gap: "0.5rem",
//             justifyContent: "center",
//             padding: "0 1rem",
//             marginTop: "1rem",
//           }}
//         >
//           <Button
//             className="responsive_fontsize16"
//             variant="contained"
//             color="secondary"
//             fullWidth
//             sx={{
//               textTransform: "capitalize",
//               borderRadius: "0.375rem",
//               color: "white",
//               height: "2rem",
//             }}
//             onClick={handleDetailsClick}
//           >
//             Details
//           </Button>
//           <Button
//             className="responsive_fontsize16"
//             variant="contained"
//             color="primary"
//             fullWidth
//             onClick={handleAddToCart}
//             sx={{
//               textTransform: "capitalize",
//               borderRadius: "0.375rem",
//               color: "white",
//               height: "2rem",
//             }}
//           >
//             Add To Cart
//           </Button>
//         </Box>
//       </Box>
//     </CardContent>
//   );
// }

// FoodCardContent.propTypes = {
//   fooditem: PropTypes.shape({
//     day_price: PropTypes.string.isRequired,
//     night_price: PropTypes.string.isRequired,
//     item_image: PropTypes.object.isRequired,
//   }).isRequired,
//   subcategory: PropTypes.string.isRequired,
//   handleAddToCart: PropTypes.func.isRequired,
//   item_image: PropTypes.object,
// };

// export default FoodCardContent;