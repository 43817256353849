import React, { useState } from 'react';
import { Typography, Button, IconButton, Drawer, List, ListItem, ListItemText, Box, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/eduLogo.png';
import HeaderInfo from './header_info';

const navItems = [
  { name: "Home", to: "/" },
  { name: "Study in Nepal", to: "/study-in-nepal" },
  { name: "Study Abroad", to: "/study-abroad" },
  { name: "About", to: "/about" },
  { name: "Alumni", to: "/alumni" },
  { name: "Contact", to: "/contact" },
];

const menuItemStyles = {
  fontSize: { xs: '12px', sm: '13px', md: '14px', lg: '16px' },
  fontWeight: '550',
  padding: '3px 16px',
  color: (selectedCourse, course) => selectedCourse === course ? '#E3B81E' : '#136CB5',
  '&:hover': {
    transform: 'scale(1.05)',
    transition: 'all 0.3s ease',
  },
};

function Navbar({ prepCourses, abroadCourses, nepaliCourses }) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const toggleDrawer = () => {
    setDrawerOpen((prev) => !prev);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handlePreparationClick = (courseTitle) => {
    setSelectedCourse(courseTitle);
    const selectedCourse = abroadCourses.find(course => course.courseTitle.split('(')[0].trim() === courseTitle);
    if (selectedCourse) {
      navigate('/abroad-course-desc', { state: selectedCourse });
    } else {
      const selectedNepaliCourse = nepaliCourses.find(course => course.courseTitle.split('(')[0].trim() === courseTitle);
      if (selectedNepaliCourse) {
        navigate('/desc', { state: selectedNepaliCourse });
      }
    }
  };

  return (
    <Box component="nav" sx={{ position: 'sticky', top: 0, zIndex: 1000, display: 'flex', flexDirection: "column", alignItems: 'center', background: 'linear-gradient(90deg, #49BBBD 0%, #136CB5 100%)', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', transition: 'top 0.3s' }}>

         {/* Top bar with contact and social info */}
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <HeaderInfo />
      </Box>

       {/* Main Navbar section */}
      <Box sx={{ width: { xs: '90%', sm: "100%" }, display: "flex", justifyContent: 'space-between', alignItems: 'center', padding: { xs: '10px 0px' } }}>

        {/* Logo Section */}
        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: { xs: '0.8rem', md: '3rem' }, backgroundColor: '#ffffff', borderRadius: '50%', width: { xs: '60px', sm: '70px' }, height: { xs: '55px', sm: '60px' } }}>
          <Link to="/" style={{ textDecoration: 'none' }}>
            <img src={logo} alt="Logo" style={{ height: '100%', width: '100%' }} />
          </Link>
        </Box>

         {/* Desktop Navigation Links */}
        <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: { xs: '5px', sm: '15px', md: '20px', lg: '30px' }, marginRight: '1.5rem', alignItems: "center" }}>
          {navItems.map((item) => (
            <Typography key={item.to} sx={{ fontSize: { xs: '12px', sm: '14px', md: '16px', lg: "18px" },
             color: location.pathname === item.to ? '#E3B81E' : '#ffffff', fontWeight: 'bold', cursor: 'pointer', 
             transition: 'color 0.3s, transform 0.3s', '&:hover': { color: '#E3B81E', transform: 'scale(1.1)' }, 
             padding: { xs: '2px 5px', sm: '4px 8px', md: '5px 10px' },
              borderRadius: '5px' }}>
              <Link to={item.to} style={{ color: 'inherit', textDecoration: 'none' }}>
                {item.name}
              </Link>
            </Typography>
          ))}

          {/* Preparation Dropdown Menu */}
          <Box>
            <Button variant='contained' onClick={handleMenuClick} sx={{ fontSize: '16px', fontWeight: 'bold', color: '#ffffff', backgroundColor: 'transparent', cursor: 'pointer', borderRadius: '5px', '&:hover': { color: '#E3B81E' }, marginRight: { md: "1rem" } }}>
              Preparation
            </Button>
          </Box>
          <Box>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose} sx={{ '& .MuiPaper-root': { background: '#E6F4F4', borderRadius: 'none', boxShadow: 'none', marginTop:{xs:'none', sm:'1.3rem' }} }}>
              {prepCourses.map((course) => (
                <MenuItem key={course} onClick={() => handlePreparationClick(course)} sx={{ ...menuItemStyles, color: selectedCourse === course ? '#E3B81E' : '#136CB5' }}>
                  {course}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Box>

        {/* Mobile Menu Icon */}
        <IconButton onClick={toggleDrawer} sx={{ marginRight: "0.8rem", display: { xs: 'block', md: 'none' }, color: '#fff', backgroundColor: '#136CB5', '&:hover': { backgroundColor: '#49BBBD' } }}>
          <MenuIcon />
        </IconButton>
      </Box>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
        <Box sx={{ width: '200px', background: 'linear-gradient(90deg, #49BBBD 0%, #136CB5 100%)', height: '100%', color: '#fff', padding: '20px' }}>
          <IconButton onClick={toggleDrawer} sx={{ color: '#fff', marginBottom: '20px' }}>
            <CloseIcon />
          </IconButton>
          <List>
            {navItems.map((item) => (
              <ListItem button key={item.to} onClick={toggleDrawer} sx={{ color: location.pathname === item.to ? '#E3B81E' : '#fff' }}>
                <Link to={item.to} style={{ color: 'inherit', textDecoration: 'none' }}>
                  <ListItemText primary={item.name} />
                </Link>
              </ListItem>
            ))}
          </List>
          <Box sx={{ marginTop: '20px' }}>
            <Button onClick={handleMenuClick} variant='contained' sx={{ fontSize: '16px', fontWeight: 'bold', color: '#ffffff', backgroundColor: 'transparent', cursor: 'pointer', transition: 'color 0.3s', borderRadius: '5px', '&:hover': { color: '#E3B81E' } }}>
              Preparation
            </Button>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose} sx={{ '& .MuiPaper-root': { background: '#E6F4F4', borderRadius: 'none', marginTop:{xs:'none', sm: '1.3rem'} } }}>
              {prepCourses.map((course) => (
                <MenuItem key={course} onClick={() => handlePreparationClick(course)} sx={{ ...menuItemStyles, color: selectedCourse === course ? '#E3B81E' : '#136CB5' }}>
                  {course}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}

export default Navbar;
