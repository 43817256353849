import React, { useEffect, useState } from "react";
import {
    Typography, Card, CardContent, Dialog, Container, DialogActions, DialogContent, DialogTitle, Button, Stack
} from '@mui/material';
import { Info, Announcement } from '@mui/icons-material';

const NewsNoticeCards = ({ info, sectionTitle }) => {
    const [showMore, setShowMore] = useState(false);
    const [chooseIcon, setChooseIcon] = useState(false);
    const [openDialog, setOpenDialog] = useState(false); // State to open/close dialog
    const [selectedItem, setSelectedItem] = useState(null); // State to store the selected card item

    useEffect(() => {
        if (sectionTitle === 'Latest News') {
            setChooseIcon(true);
        }

        // Log the fetched data
        console.log("Fetched data for section:", sectionTitle);
        console.log(info); // Logs the array of fetched news or notices
    }, [info, sectionTitle]);

    // Function to convert Buffer to an image URL
    const bufferToImage = (buffer) => {
        if (buffer && buffer.data && buffer.data.length > 0) {
            const blob = new Blob([new Uint8Array(buffer.data)], { type: 'image/jpeg' });
            return URL.createObjectURL(blob);
        }
        return null;
    };

    // Function to open dialog and set the selected item
    const handleCardClick = (item) => {
        setSelectedItem(item);
        setOpenDialog(true);

        // Log the selected item when a card is clicked
        console.log("Selected Item:", item);
    };

    return (
        <Container maxWidth={false} sx={{ padding: '1rem 0px', backgroundColor: '#eaf2f8' }}>
            <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
                spacing={2}
                sx={{
                    height:'auto',
                    width: '100%',
                }}
            >
                <Typography
                    variant="h5"
                    align="center"
                    gutterBottom
                    fontFamily="Roboto-Serif"
                    sx={{
                        fontWeight: 'bold',
                        color: '#003366',
                        textTransform: 'uppercase',
                        fontSize: { xs: "22px", sm: "24px", md: "32px", lg: "42px" }
                    }}
                >
                    {sectionTitle}
                </Typography>

                <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="center"
                    sx={{
                        width: '100%',
                        flexWrap: 'wrap',
                        gap: 2,  // Ensure there's a gap between the cards horizontally
                    }}
                >
                    {info.slice(0, showMore ? info.length : 2).map((item) => (
                        <Card
                            key={item.id}
                            sx={{
                                textAlign: 'center',
                                transition: 'transform 0.3s',
                                height: 'auto',
                                margin: '0 auto',
                                borderRadius: 1,
                                width:{xs:'78%',sm:'50%',md:'89%',lg:'85%'},
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                    boxShadow: 6,
                                },
                            }}
                            onClick={() => handleCardClick(item)}
                        >
                            <CardContent>
                                {
                                       chooseIcon ? 
                                       <Info sx={{ fontSize: { xs: 30, sm: 40, md: 50 }, color: '#003366' }} /> :
                                       <Announcement sx={{ fontSize: { xs: 30, sm: 30, md: 35 }, color: '#003366' }} />
                                }
                                <Typography 
                                    variant="body1"
                                   
                                    sx={{ 
                                        fontWeight: 'bold',
                                        fontSize: { xs: '12px', sm: '15px', md: '17px' },
                                        fontFamily: 'Roboto-Serif',
                                        marginTop: 1 
                                        }}>
                                    {sectionTitle === 'Latest News' ? item.news_title : item.notice_title}
                                </Typography>
                                <Typography variant="body1" fontFamily="Roboto-Serif" sx={{ fontStyle: 'italic', marginTop: 1 }}>
                                    {sectionTitle === 'Latest News' ? item.news_date : item.notice_on}
                                </Typography>
                            </CardContent>
                        </Card>
                    ))}
                </Stack>

                <Button
                    onClick={() => setShowMore(!showMore)}
                    sx={{
                        backgroundColor: '#49BBBD',
                        fontSize: { xs: "12px", sm: "16px" },
                        '&:hover': { backgroundColor: '#ff5722' },
                        color: 'white',
                        marginTop: 2,
                        marginBottom: { xs: 3 },
                        width: 'auto',  
                        paddingX: 4,   
                    }}
                >
                    {showMore ? "View Less" : "View More"}
                </Button>

                {/* Dialog Popup for Detailed View */}
                <Dialog
                    open={openDialog}
                    onClose={() => setOpenDialog(false)}
                    maxWidth="sm" // Define a max width for small screens
                    fullWidth
                    sx={{
                        '@media (max-width:600px)': {
                            width: '90%', // For small screens (xs), set width to 90%
                        },
                        '@media (min-width:600px) and (max-width:960px)': {
                            width: '80%', // For medium screens (sm and md), set width to 80%
                        },
                        '@media (min-width:960px)': {
                            width: '100%', // For large screens (lg), set width to 60%
                        }
                    }}
                >
                    <DialogTitle>{sectionTitle === 'Latest News' ? selectedItem?.news_title : selectedItem?.notice_title}</DialogTitle>
                    <DialogContent
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center', // Center the content horizontally
                            justifyContent: 'center', // Center the content vertically
                            maxHeight: '80vh', // Allow content to scroll within the dialog
                            overflowY: 'auto', // Enable scrolling for long content
                        }}
                    >
                        {/* Check if the news/notice has an image */}
                        {sectionTitle === 'Latest News' && selectedItem?.news_photo?.data?.length > 0 ? (
                            <div
                                style={{
                                    maxHeight: '500px',
                                    overflow: 'auto', // Ensure the image container scrolls if the image is too tall
                                    marginBottom: 16,
                                    width: '100%',
                                }}
                            >
                                <img
                                    src={bufferToImage(selectedItem.news_photo)}
                                    alt="News Photo"
                                    style={{
                                        objectFit: 'contain',
                                        width: '100%',
                                        height: 'auto', // Keep aspect ratio
                                    }}
                                />
                            </div>
                        ) : sectionTitle === 'Notices' && selectedItem?.notice_photo?.data?.length > 0 ? (
                            <div
                                style={{
                                    maxHeight: '500px',
                                    overflow: 'auto', // Ensure the image container scrolls if the image is too tall
                                    marginBottom: 16,
                                    width: '100%',
                                }}
                            >
                                <img
                                    src={bufferToImage(selectedItem.notice_photo)}
                                    alt="Notice Photo"
                                    style={{
                                        objectFit: 'contain',
                                        width: '100%',
                                        height: 'auto',
                                    }}
                                />
                            </div>
                        ) : null}

                        {/* Display the news/notice details */}
                        <Typography variant="body1" sx={{ marginTop: 2 }}>
                            {sectionTitle === 'Latest News' ? selectedItem?.news_detail : selectedItem?.notice_detail}
                        </Typography>

                        {/* Display the date */}
                        <Typography variant="body2" sx={{ marginTop: 2, fontStyle: 'italic' }}>
                            Date: {sectionTitle === 'Latest News' ? selectedItem?.news_date : selectedItem?.notice_on}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => setOpenDialog(false)}
                            sx={{
                                backgroundColor: '#49BBBD',
                                '&:hover': { backgroundColor: '#ff5722' },
                                color: 'white',
                            }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Stack>
        </Container>
    );
};

export default NewsNoticeCards;