import React from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Buffer } from 'buffer';

const EventDialog = ({ open, handleClose, selectedEvent, sliderValue, setSliderValue }) => {
  if (!Array.isArray(selectedEvent) || selectedEvent.length === 0) {
    console.warn('selectedEvent is empty or not an array.');
    return null;
  }
  const currentEvent = selectedEvent[sliderValue];

  const handleNext = () => {
    if (sliderValue < selectedEvent.length - 1) {
      setSliderValue(sliderValue + 1);
    } else {
      handleClose();
    }
  };

  const handleBack = () => {
    if (sliderValue > 0) {
      setSliderValue(sliderValue - 1);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiDialog-paper': {
          backgroundColor: '#F1F1F1',
          borderRadius: '12px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          width: { xs: '90%', sm: '400px', md: '700px' },
          height: { xs: '450px', md: '750px' },
          maxWidth: 'none',
        },
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          fontWeight: 'bold',
          fontSize: { xs: '1rem', md: '1.8rem' },
          color: '#136CB5',
        }}
      >
        Special Event Announcement
        <IconButton onClick={handleClose} sx={{ color: '#136CB5' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          overflowY: 'auto',
          // maxHeight: '90vh',
          textAlign: 'center',
        }}
      >
        <Box>
          <Box
            sx={{
              maxHeight: { xs: '200px', md: '400px' },
              width: '100%',
              overflowY: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '8px',
              border: '4px solid #136CB5',
              marginBottom: '1rem',
            }}
          >
            <img
              src={
                currentEvent.events_photo
                  ? `data:image/${currentEvent.imageFormat || 'jpeg'};base64,${Buffer.from(
                      currentEvent.events_photo
                    ).toString('base64')}`
                  : '/path/to/default-image.jpg'
              }
              alt={currentEvent.events_title || 'Event Image'}
              style={{
                width: '100%',
                height: 'auto',
                objectFit: 'contain',
              }}
            />
          </Box>
          <Typography
            variant="h5"
            sx={{
              marginTop: '1rem',
              fontWeight: 'bold',
              fontSize: { xs: '1rem', md: '1.5rem' },
              color: '#136CB5',
            }}
          >
            {currentEvent.events_title}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              marginTop: '0.5rem',
              fontSize: { xs: '0.9rem', md: '1.2rem' },
              color: '#136CB5',
            }}
          >
            {currentEvent.events_date}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              marginTop: '1rem',
              fontSize: { xs: '0.8rem', md: '1rem' },
            }}
          >
            {currentEvent.events_detail}
          </Typography>
        </Box>
        <DialogActions sx={{gap:'31rem'}}>
          {/* Back Button */}
          <Button
            onClick={handleBack}
            sx={{
              background: 'linear-gradient(90deg, #136CB5 0%, #49BBBD 100%)',
              color: 'white',
              '&:hover': {
                background: 'linear-gradient(90deg, #49BBBD 0%, #136CB5 100%)',
                
              },
            }}
            disabled={sliderValue === 0} // Disable "Back" button when at the first event
          >
            Back
          </Button>
          {/* Next or Close Button */}
          <Button
            onClick={handleNext}
            sx={{
              background: 'linear-gradient(90deg, #136CB5 0%, #49BBBD 100%)',
              color: 'white',
              '&:hover': {
                background: 'linear-gradient(90deg, #49BBBD 0%, #136CB5 100%)',
              },
            }}
          >
            {sliderValue < selectedEvent.length - 1 ? 'Next' : 'Close'}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default EventDialog;








