import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Container, Stack, Grid } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YoutubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import FooterBar from '../../layout/footer_bar/footer_bar_main';
import apiClient from '../../config/axiosConfig';
import { toast } from 'react-toastify';
import SocialMediaBar from '../SocialMedia/socialMedia';

function Contact() {
    const [studentQuery, setStudentQuery] = useState({
        s_name: '',
        s_contact: '',
        s_message: '',
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(studentQuery);
        apiClient.post(`/studentQuery`, studentQuery).then((response) => {
            console.log(response);
            if (response.data.success) {
                toast.success("Thank you", {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            } else {
                toast.error("Sorry, something went wrong.", {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
        }).catch((error) => {
            console.log(error);
        });
        setStudentQuery({
            s_name: '',
            s_contact: '',
            s_message: '',
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setStudentQuery((prev) => ({ ...prev, [name]: value }));
    };

    return (
        <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
            <Container maxWidth="lg" sx={{ my: 5, flex: 1 }}>
                <Typography
                    variant="h4"
                    component="h1"
                    gutterBottom
                    align="center"
                    sx={{
                        fontWeight: 'bold',
                        color: '#003366',
                        mt: { xs: 4, md: 6 },
                        fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
                        fontFamily: 'Roboto-Serif',
                        textTransform: 'uppercase',
                      }}
                >
                    Contact Us
                </Typography>

                <Grid container spacing={3} sx={{ display: 'flex', flexDirection:'column' }}>
                    {/* Form Section */}
                    <Grid item xs={12} >
                        <form onSubmit={handleSubmit}>
                            <Stack spacing={2} width='100%'>
                                <TextField
                                    required
                                    label="Name"
                                    fullWidth
                                    variant="outlined"
                                    name="s_name"
                                    value={studentQuery.s_name}
                                    onChange={handleChange}
                                    sx={{ fontSize: { xs: '14px', sm: '16px' } }}
                                />
                                <TextField
                                    required
                                    type="tel"
                                    label="Contact"
                                    fullWidth
                                    variant="outlined"
                                    name="s_contact"
                                    value={studentQuery.s_contact}
                                    onChange={handleChange}
                                    sx={{ fontSize: { xs: '14px', sm: '16px' } }}
                                />
                                <TextField
                                    required
                                    label="Message"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    name="s_message"
                                    value={studentQuery.s_message}
                                    onChange={handleChange}
                                    sx={{ fontSize: { xs: '14px', sm: '16px' } }}
                                />
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    sx={{
                                        maxWidth: 200,
                                        alignSelf: 'center',
                                        fontSize: { xs: '12px', sm: '16px' },
                                    }}
                                >
                                    Send Message
                                </Button>
                            </Stack>
                        </form>
                    </Grid>

                    {/* Map Section */}
                    <Grid item xs={12}>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.4815324552615!2d85.31934017613415!3d27.702415025707804!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19a8f83d29b7%3A0x2ebd5eaf76c1559c!2sEducation%20Dot%20Com!5e0!3m2!1sne!2snp!4v1738071641599!5m2!1sne!2snp"
                            width="100%"
                            height="300"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                            sx={{ borderRadius: '8px' }}
                        ></iframe>
                    </Grid>
                </Grid>

                {/* Additional Sections */}
                <Typography
                    variant="h5"
                    component="h2"
                    gutterBottom
                    sx={{
                        marginTop:'30px',
                        fontWeight: "bold", 
                        color: "#003366", 
                        fontSize: { xs: '1.2rem', sm: '1.5rem' },
                        textAlign: 'center',
                    }}
                >
                    Get in Touch
                </Typography>
                <Stack spacing={1} direction="row" justifyContent="center" alignItems="center">
                    <EmailIcon sx={{ fontSize: '14px',  color:"textSecondary" }} />
                    <Typography variant="body1" color="textSecondary" sx={{ fontSize: '14px' }}>
                        info@educationpvt.com
                    </Typography>
                </Stack>
                <Stack spacing={1} direction="row" justifyContent="center" alignItems="center" sx={{ mt: 2 }}>
                    <PhoneIcon sx={{ fontSize: 20}} />
                    <Typography variant="body1" color="textSecondary" sx={{ fontSize: '14px' }}>
                        9841354464
                    </Typography>
                </Stack>

                {/* Social Media Section */}
                <Box>
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                        sx={{
                            marginTop:'30px',
                            fontWeight: "bold", 
                            color: "#003366", 
                            fontSize: { xs: '1.2rem', sm: '1.5rem' },
                            textAlign: 'center',
                        }}
                    >
                        Follow Us
                    </Typography>
                    <Stack direction="row" spacing={2} justifyContent="center">
                        <a href="https://www.facebook.com/educationdotcom" target="_blank" rel="noopener noreferrer">
                            <FacebookIcon  sx={{ color: '#4267B2', fontSize: { xs: 25, sm: 30 } }} />
                        </a>
                        {/* <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                            <TwitterIcon fontSize="large" sx={{ color: '#1DA1F2', fontSize: { xs: 30, sm: 40 } }} />
                        </a> */}
                        {/* <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                            <LinkedInIcon fontSize="large" sx={{ color: '#0077B5', fontSize: { xs: 30, sm: 40 } }} />
                        </a> */}
                        <a href="https://www.instagram.com/education.com2009/" target="_blank" rel="noopener noreferrer">
                            <InstagramIcon sx={{ color: '#C13584', fontSize: { xs: 25, sm: 30 } }} />
                        </a>
                        <a href="https://youtube.com/@eductioncom?si=_Mm-lnFrvdMwKVqa" target="_blank" rel="noopener noreferrer">
                            <YoutubeIcon  sx={{ color: '#FF0000', fontSize: { xs: 25, sm: 30 } }} />
                        </a>
                    </Stack>
                </Box>
            </Container>
            <SocialMediaBar />
            <FooterBar />
        </Box>
    );
}

export default Contact;
