import React, { useEffect, useState } from "react";
import { Box, Button, Modal, Backdrop, Fade, useMediaQuery, Typography } from "@mui/material";
import ReactPlayer from 'react-player';
import apiClient from "../../../../config/axiosConfig";
import ActionAreaCard from './card';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Carousel() {
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [selectedTab, setSelectedTab] = useState("image"); // Default selected tab is "image"
  const [video, setVideo] = useState([]);
  const [loadImage, setLoadImage] = useState([]);
  const [videoOrder, setVideoOrder] = useState(video);

  const [sliderIndex, setSliderIndex] = useState(0);

  // Use media query to determine screen size
  const isXs = useMediaQuery("(max-width:600px)");
  const isSm = useMediaQuery("(max-width:900px)");
  const isMd = useMediaQuery("(min-width:900px)");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [imageResponse, videoResponse] = await Promise.all([
          apiClient.get("/gallery"),
          apiClient.get("/videos"),
        ]);

        const base64Images = await Promise.all(imageResponse.data.GalleryDetail.map(async (image) => {
          const base64String = await getBase64Image(image.gallery_image);
          return {
            ...image,
            base64Image: base64String,
          };
        }));

        setLoadImage(base64Images);
        setVideo(videoResponse.data.videoDetail);
        setVideoOrder(videoResponse.data.videoDetail); // Set initial order for videos

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleVideoClick = (videoLink) => {
    setModalContent({ type: "video", content: videoLink });
    setOpen(true);
    setSelectedTab("video"); // Set the selected tab to video
  };

  const handleClose = () => setOpen(false);

  const getBase64Image = (imageBuffer) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(new Blob([new Uint8Array(imageBuffer.data)]));
    });
  };

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    beforeChange: (current, next) => setSliderIndex(next),
    responsive: [
      {
        breakpoint: 1450,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    customPaging: (i) => {
      return (
        <div
          style={{
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            background: i === sliderIndex ? "#656566" : "lightgray",
            display: "inline-block",
            margin: "0 5px",
          }}
        />
      );
    },
    appendDots: (dots) => {
      const maxDotsToShow = 5;
      const limitedDots = dots.slice(0, maxDotsToShow);
      return (
        <ul style={{ display: "flex", justifyContent: "center", padding: 0 }}>
          {limitedDots}
        </ul>
      );
    },
  };

  return (
    <Box sx={{ textAlign: "center", mt: 3,width: '85%' }}>
      {/* <Typography
        className="responsive_fontsize32"
        sx={{
          fontFamily: "Roboto Serif",
          fontWeight: "700",
          textAlign: "center",
          mb: 2,
        }}
      >
        Gallery
      </Typography> */}

      <Box sx={{ mb: 4 }}>
        <Button
          variant="contained"
          color={selectedTab === "image" ? "success" : "primary"}
          onClick={() => setSelectedTab("image")}
          sx={{ mr: 2 }}
        >
          Image
        </Button>
        <Button
          variant="contained"
          color={selectedTab === "video" ? "success" : "primary"}
          onClick={() => setSelectedTab("video")}
        >
          Video
        </Button>
      </Box>

      {selectedTab === "image" && (
        <Slider {...settings}>
          {loadImage.map((image, index) => (
            <Box key={index} sx={{ px: 2 }}>
              <ActionAreaCard
                image={image.base64Image}
                title={image.image_title}
                description={image.image_description}
              />
            </Box>
          ))}
        </Slider>
      )}

      {selectedTab === "video" && (
        <Slider {...settings}>
          {videoOrder.map((videoItem, index) => (
            <Box key={index} sx={{ px: 2 }}>
              <ActionAreaCard
                image={`https://img.youtube.com/vi/${new URL(videoItem.video_link).searchParams.get('v')}/0.jpg`}
                title={videoItem.video_title}
                description={videoItem.video_description}
                onClick={() => handleVideoClick(videoItem.video_link)}
              />
            </Box>
          ))}
        </Slider>
      )}

      {/* Modal for image or video */}
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'white',
              borderRadius: 2,
              boxShadow: 24,
              width: { xs: '22rem', md: '28rem' },
              height: { xs: '23rem', md: '22rem' },
            }}
          >
            {modalContent?.type === "image" ? (
              <img
                src={modalContent.content}
                alt="Modal Content"
                style={{
                  width: '100%',
                  objectFit: 'cover',
                }}
              />
            ) : modalContent?.type === "video" ? (
              <ReactPlayer
                url={modalContent.content}
                width="100%"
                controls
              />
            ) : null}
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
}

export default Carousel;
