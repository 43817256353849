import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Typography, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fade from '@mui/material/Fade';

export default function AccordionTransition({ universityPrograms }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <Box>
      <Accordion
        expanded={expanded}
        onChange={handleExpansion}
        slots={{ transition: Fade }}
        slotProps={{ transition: { timeout: 400 } }}
        square={true}
        sx={[
          expanded
            ? {
                '& .MuiAccordion-region': {
                  height: 'auto',
                },
                '& .MuiAccordionDetails-root': {
                  display: 'block',
                },
              }
            : {
                '& .MuiAccordion-region': {
                  height: 0,
                },
                '& .MuiAccordionDetails-root': {
                  display: 'none',
                },
              },
          {
            boxShadow: 'none',
            backgroundColor: 'transparent',
            borderRadius: 0,
            border: 'none',
            borderBottom: '1px solid gray',
            padding: '0px',
            '&:before': {
              display: 'none',
            },
          },
        ]}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
            padding: '0px',
          }}
        >
           <Typography variant="body1" fontWeight="550" sx={{ 
                          fontSize: { xs: '0.875rem', sm: '1rem', md: '1.125rem' } // Responsive font size
                        }}>
            {universityPrograms.universityName}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box component="ul" sx={{ paddingLeft: 2 }}>
            {universityPrograms.programs.map((program, index) => (
              <Box
                component="li"
                key={index}
                variant="body2"
                sx={{
                  listStyleType: 'disc',
                  fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' },
                  //fontWeight: '500',
                  fontFamily: 'calibri',
                  // backgroundColor:'red'
                }}
              >
                <Typography variant="subtitle1">{program}</Typography>
              </Box>
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
