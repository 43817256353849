import React from 'react';
import { Container, Box } from '@mui/material';
import NewsSection from './newsSection';
import NoticeSection from './noticeSection';

function Information() {
  return (
    <Container
      maxWidth={false}
      sx={{
        backgroundColor: '#eaf2f8',
        py: 3, // Adds padding to the top and bottom
      }}
    >
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: { xs: 'column', md: 'row' }, // Stacks in a column on small screens
          gap: 2, // Adds space between NoticeSection and NewsSection
        }}
      >
        {/* Notice Section */}
        <Box
          sx={{
            mb: { xs: 2, md: 0 }, // Adds margin-bottom for extra space in smaller screens
            flex: 1,
          }}
        >
          <NoticeSection />
        </Box>

        {/* News Section */}
        <Box sx={{ flex: 1 }}>
          <NewsSection />
        </Box>
      </Container>
    </Container>
  );
}

export default Information;
