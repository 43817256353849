import React from 'react';
import { Box} from '@mui/material';

import OurServices from '../section/services';
import Testimonial from '../section/Testonomial/Testimonial.jsx';
import Information from '../section/newsNotices.jsx';
import FooterBar from '../../layout/footer_bar/footer_bar_main';
import Videos from '../section/Media/Card/Videos.jsx';
import ChooseUs from './chooseUsSection';
import SocialMediaBar from '../SocialMedia/socialMedia.jsx';

import StudyNepalSlider from './StudyNepalSlider';
import BachelorPrograms from './ManagementItPrograms.jsx';

const StudyInNepal = ({studentTestimonial,nepaliCourses}) => {

    return (
        <Box sx={{
            backgroundColor: '#f5f5f5',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            // backgroundColor:"green",
            // padding: "20px"
        }}>
            {/* Main Heading */}
            
            <StudyNepalSlider nepaliCourses={nepaliCourses}/>
            {/* Why Choose Us? Section */}
           
            <ChooseUs />
            <BachelorPrograms />
            <OurServices serviceRequested={'Study In Nepal'}/>
            <Testimonial studentTestimonial={studentTestimonial} />
            <Information />
            <Videos />
            <SocialMediaBar/>
            <FooterBar />
        </Box>
    );
};

export default StudyInNepal;
