import React from 'react';
import { Box, Typography, Container, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FooterBar from '../../../layout/footer_bar/footer_bar_main';
import { useLocation } from 'react-router';
import { Buffer } from 'buffer';
import SocialMediaBar from '../../SocialMedia/socialMedia';

function CountryDetail() {
  const location = useLocation();
  const country = location.state;

  // Log the fetched data for debugging
  console.log('Fetched Country Data:', country);

  if (!country) {
    return <Typography variant="h6" align="center">Country data is missing or not available</Typography>;
  }

  const flagData = country.flag ? `url(data:image/${country.imageFormat};base64,${Buffer.from(country.flag).toString('base64')})` : null;

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        minHeight: '100vh',
        // backgroundColor:"red"
      }}
    >
      <Container 
        sx={{ 
          flex: 1, 
          padding: '40px 20px' ,
          // backgroundColor:"green"
          }}>
        {/* Title Section */}
        <Typography
          variant="h3"
          gutterBottom
          align="center"
          sx={{
            fontWeight: '550',
            color: '#003366',
            fontFamily: "Roboto-Serif",
            textTransform: "uppercase",
            fontSize: { xs: '1.8rem', sm: '2.5rem', md: '3rem' },
            letterSpacing: '2px',
            marginBottom: '30px',
          }}
        >
          {country.countryName}
        </Typography>

        {/* Flag Image Section */}
        {flagData && (
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',  // Horizontally center the image
            marginBottom: '40px',
            height: { xs: '250px', sm: '350px', md: '600px' },
            backgroundImage: flagData,
            backgroundPosition: 'center',
            backgroundSize:"cover",
            backgroundRepeat: 'no-repeat',
            borderRadius: '6px',
            
          }} />
        )}

        {/* Why Study Here Section */}
        <Box 
          sx={{ 
            textAlign: 'center', 
            marginBottom: {xs:"20px",sm:'30px',md:"40px"},
            // backgroundColor:"yellow"
            }}>
          <Typography variant="h5" gutterBottom
            sx={{ 
              fontWeight: 'bold', 
              // marginBottom: '12px', 
              fontSize: { xs: '1.3rem', sm: '2rem' },
              fontFamily:"Roboto-Serif"
              }}>
            Why Study Here?
          </Typography>
          <Typography variant="body1" sx={{
            fontFamily: "Poppins",
            textAlign: 'justify',
            marginBottom: '12px',
            color: '#333',
            // paddingLeft: '25px',
            fontSize: { xs: '1rem', sm: '1.125rem' },
          }}>
            {country.reasonToChoose}
          </Typography>
        </Box>

        {/* Guidelines Section */}
        <Box 
          sx={{ 
            marginBottom: {xs:"20px",sm:"30px",md:"40px"} ,
            // border:"1px solid blue"
            }}>
              <Typography variant="h5" 
                sx={{ 
                  fontWeight: 'bold', 
                  // marginBottom: '20px', 
                  textAlign: 'center', 
                  fontSize: { xs: '1.3rem', sm: '2rem' },
                  fontFamily:"Roboto-Serif"
                }}>
                Guidelines
              </Typography>

          {Array.isArray(country.admissionGuideline) && country.admissionGuideline.length > 0 ? (
            <Box 
              sx={{ 
                textAlign: 'justify',  
                fontSize: { xs: '1rem', sm: '1.125rem' },
                // border:"1px solid red"
               }}>
                <ol 
                  style={{
                    // backgroundColor:"green",
                    paddingLeft:"15px"
                  }}
                >
                  {country.admissionGuideline.map((guideline, index) => {
                    // console.log('Guideline item:', guideline); // Log individual guideline data
                    return (
                      <li key={index} 
                        style={{
                          fontFamily:"Poppins",
                          // marginLeft:"-26px",
                          margin:"10px 0px"
                          }}>
                        <strong>{guideline.topic}</strong>: {guideline.description}
                        {guideline.subtopics && guideline.subtopics.length > 0 &&(
                          <ul
                            style={{
                              // backgroundColor:"green",
                              paddingLeft:"20px"
                            }}
                          >
                            {guideline.subtopics.map((sub, subIndex) => (
                              sub.name && sub.description && (
                                <li key={subIndex} 
                                  style={{
                                    listStyleType: "disc",
                                    // marginLeft: "-25px",
                                    margin: "3px 0px",
                                    // backgroundColor:"red"
                                  }}>
                                  <strong>{sub.name}:</strong> {sub.description}
                                </li>
                              )
                            ))}
                          </ul>
                        )}
                      </li>
                    );
                  })}
                </ol>
            </Box>
          ) : (
            <Typography variant="body1" align="center">No Guidelines available.</Typography>
          )}
        </Box>

        {/* Popular Universities Section */}
        <Box sx={{ marginBottom: {xs:"20px",sm:"30px",md:"40px"} }}>
          <Typography variant="h5" 
            sx={{ 
              fontWeight: 'bold', 
              textAlign: 'center', 
              fontSize: { xs: '1.3rem', sm: '2rem' },
              fontFamily:"Roboto-Serif",
              // marginBottom: '20px' 
              }}>
            Popular Universities
          </Typography>
          {country.popularUniversities && country.popularUniversities.length > 0 ? (
            <Box 
              sx={{ 
                textAlign: 'left', 
                // paddingLeft: '20px', 
                fontSize: { xs: '1rem', sm: '1.125rem' },
                // border:"1px solid red"
                }}>
              <ol>
                {country.popularUniversities.map((university, index) => (
                  <li key={index}
                  style={{
                    fontFamily:"Poppins",
                    marginLeft:"-26px"
                    }}
                  >{university}</li>
                ))}
              </ol>
            </Box>
          ) : (
            <Typography variant="body1" align="center">No popular universities available.</Typography>
          )}
        </Box>

        {/* FAQ Section */}
        <Box >
          <Typography variant="h5" gutterBottom
          sx={{ 
            fontWeight: 'bold', 
            textAlign: 'center', 
            fontSize: { xs: '1.3rem', sm: '2rem' }, 
            // marginBottom: '20px' ,
            fontFamily:"Roboto-Serif",
            }}>
            Frequently Asked Questions
          </Typography>
          <Box>
            {country.faq && country.faq.length > 0 ? (
              country.faq.map((item, index) => {
                console.log('FAQ item:', item); // Log individual FAQ data
                return (
                  <Accordion key={index} sx={{ marginBottom: '10px', '&:before': { display: 'none' } }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${index}-content`}
                      id={`panel${index}-header`}
                      sx={{
                        '& .MuiAccordionSummary-expandIcon': { color: '#fff' },
                      }}
                    >
                      <Typography sx={{ fontWeight: 'bold',fontFamily:"Poppins",fontFamily:{xs:"12px",sm:"14px"} }}>{item.faqQuestion}</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ backgroundColor: '#f5f5f5' }}>
                      <Typography
                        sx={{ fontFamily:"Poppins",fontFamily:{xs:"12px"} }}
                      >{item.faqAnswer}</Typography>
                    </AccordionDetails>
                  </Accordion>
                );
              })
            ) : (
              <Typography variant="body1" align="center">No FAQ data available.</Typography>
            )}
          </Box>
        </Box>

        <SocialMediaBar />
      </Container>

      <FooterBar />
    </Box>
  );
}

export default CountryDetail;
