import React from 'react';
import { Box, Typography } from '@mui/material';

function Header() {
  return (
    <Box sx={{ textAlign: 'center', mb: 4,}}>
      <Typography
       variant="h4"
       component="h1"
       gutterBottom
       align="center"
       sx={{
         fontWeight: 'bold',
         color: '#003366',
         mt: { xs: 4, md: 6 },
         fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
         fontFamily: 'Roboto-Serif',
         textTransform: 'uppercase',
       }}
      >
        Study Destinations
      </Typography>
      <Box>
      <Typography
        variant="body1"
        sx={{
          
          color: 'textSecondary',
          fontFamily: 'Poppins',
          mt: 2,
          fontSize: { xs: '14px', sm: '16px', md: '18px' },
        }}
      >
        Explore the best countries for higher education.
      </Typography>
      </Box>
    </Box>
  );
}

export default Header;
