import React,{useState} from 'react';
import { Card, CardActionArea,Button,Container, CardContent, CardMedia, Box, Typography } from '@mui/material';

function CountryCard({ image, title, description }) {
  const[isExpanded, setIsExpanded] = useState(false);
  // Function to toggle the expanded state
  const handleToggleDescription = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <Box sx = {{ backgroundColor: '#f5f5f5',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            
             }}>
      {/* <Container  > */}
         <Card
            sx={{
             display: 'flex',
              // backgroundColor:'blue',
              flexDirection: 'column',
              // height: 'auto',

               justifyContent:'center', 
               alignContent:'center',
              //marginRight:{xs:'4rem'},
                backgroundSize:'contain',
                // position:'relative',
              
           width: { xs: '105%', sm: '95%', md: '97%', lg: '110%' }, 
          //  height:{xs:'50%'},
          borderRadius: '12px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', 
          transition: 'transform 0.3s, box-shadow 0.3s', 
          '&:hover': {
            transform: 'scale(1.05)', 
            boxShadow: '0 8px 20px rgba(0, 0, 0, 0.15)',
          },
          // backgroundColor:'red',
          // margin: '16px',
        }}
      >
        <CardActionArea sx={{ flex: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CardMedia
              component="img"
              height="190"
              image={image}
              alt={title}
            />
          </Box>
          <Box sx={{ padding: 0.8 }}>
            <CardContent sx={{ flex: 1 }}>
              <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: 'bold',  fontSize: { xs: '1.25rem', sm: '1.5rem', md: '1.75rem' }, }}>
                {title}
              </Typography>
              <Typography 
                variant="body2" 
                sx={{ 
                  color: 'text.secondary', 
                  display: '-webkit-box', 
                  WebkitLineClamp: 3,  // Limit description to 3 lines
                  WebkitBoxOrient: 'vertical', 
                  overflow: 'hidden', 
                  textOverflow: 'ellipsis' // Truncate overflow text with ellipsis
                }}
              >
                {description}
              </Typography>
                 {/* Toggle button to show/hide full description */}
                 <Button
                onClick={handleToggleDescription}
                sx={{
                  textTransform: 'none',
                  padding: 0,
                  fontSize: '0.875rem',
                  color: '#49BBBD',
                  '&:hover': {
                    color: '#ff5722',
                  },
                }}
              >
                {isExpanded ? 'See less' : 'See more'}
              </Button>
            </CardContent>
          </Box>
        </CardActionArea>
      </Card>
    {/* </Container> */}
    </Box>
  );
}

export default CountryCard;
