import React, { useState } from 'react';
import {
    Box,
    Typography,
    Container,
    Card,
    CardContent,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Stack
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FooterBar from '../../layout/footer_bar/footer_bar_main';
import ApplicationFormDialog from '../Courses/courseform';
import { useLocation } from 'react-router';
import SocialMediaBar from '../SocialMedia/socialMedia';

const AbroadCourseDescriptionPage = () => {
    const [dialogOpen, setDialogOpen] = useState(false);

    // Get course data from the passed state in location
    const location = useLocation();
    const course = location.state || {}; // Default to empty object if no state is passed

    // Extract necessary details from the course object
    const { courseTitle, overview, testFormat, preparationTips, faq } = course;

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Container maxWidth="lg" sx={{ flex: 1, py: 4 }}>
                <Typography variant="h3" component="h1" gutterBottom align="center" 
                sx={{ 
                    fontWeight: 'bold', 
                    color: '#003366', 
                    fontSize: { xs: '1.2rem', sm: '2rem', md: '2.5rem' }, 
                    mt:{xs:1,sm:2,md:4},
                    fontFamily:"Roboto-Serif" 
                    }}>
                    {courseTitle}
                </Typography>

                <Typography variant="body1" component="p" 
                sx={{
                    fontSize: { xs: '0.875rem', sm: '1rem', md: '1.25rem' },
                    fontFamily:"poppins",
                    textAlign: "justify",
                    mb:1
                  }}
                >
                    {overview}
                </Typography>

                <Typography variant="h4" component="h2" gutterBottom 
                sx={{
                    fontSize: { xs: '1.2rem', sm: '1.75rem', md: '2rem' },
                    fontWeight:"bold",
                    fontFamily:"Roboto-Serif",
                    mt:2
                    // backgroundColor:"green"
                  }}>
                    Test Format
                </Typography>
                
                <Stack direction="column" spacing={1} flexWrap="wrap">
                    {testFormat?.map((format, index) => (
                        <Card sx={{ width: '100%', mb: 2}} key={index}>
                            <CardContent>
                                <Typography variant="h6" 
                                sx={{
                                    fontSize: { xs: '0.9rem', sm: '1.125rem', md: '1.25rem' },
                                    fontWeight: 'bold',
                                    fontFamily:"poppins"
                                  }}>
                                    {format.topic}
                                </Typography>
                                <Typography variant="body2" sx={{
                                    fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' },
                                    // marginTop: '1rem',
                                    fontFamily: "poppins"
                                    }}>
                                    {format.description}
                                </Typography>
                            </CardContent>
                        </Card>
                    ))}
                </Stack>

                <Typography variant="h4" component="h2"  
                sx={{
                    fontSize: { xs: '1.2rem', sm: '1.75rem', md: '2rem' },
                    fontWeight:"bold",
                    fontFamily:"Roboto-Serif",
                    mt:3
                    // backgroundColor:"green"
                  }}>
                    Preparation Tips
                </Typography>
                <ul>
                    {preparationTips?.map((tip, index) => (
                        <li key={index}
                        style={{
                            // backgroundColor:"greenyellow",
                            marginLeft:"-18px"
                          }}
                        >
                            <Typography variant="body1" 
                            sx={{
                                fontSize: { xs: '0.875rem', sm: '1rem', md: '1.125rem' },
                                fontFamily: "poppins"
                              }}>
                                {tip}
                            </Typography>
                        </li>
                    ))}
                </ul>

                <Typography variant="h4" component="h2" gutterBottom 
                    sx={{
                        fontSize: { xs: '1.2rem', sm: '1.75rem', md: '2rem' },
                        fontWeight:"bold",
                        fontFamily:"Roboto-Serif",
                        mt:3
                        // backgroundColor:"green"
                    }}
                >
                    Frequently Asked Questions
                </Typography>
                {faq?.map((faqItem, index) => (
                    <Accordion key={index} sx={{ mb: 2 }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}-content`} id={`panel${index}-header`}>
                            <Typography fontWeight="bold"
                            sx={{
                                fontSize: { xs: '0.875rem', sm: '1.125rem', md: '1.25rem' },
                                fontFamily:"poppins"
                              }}>
                                {faqItem.question}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{
                                fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' },
                                fontFamily:"poppins"
                            }}>
                                {faqItem.answer}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}

                <Box sx={{ textAlign: 'center', mt: 4 }}>
                    <Button variant="contained" onClick={handleDialogOpen} sx={{ mr: 2, backgroundImage: 'linear-gradient(90deg, #49BBBD 0%, #136CB5 100%)', color: 'white' }}>
                        Apply Now
                    </Button>

                    <Button variant="outlined" color="secondary" href="/contact">
                        Contact Us
                    </Button>
                </Box>
                <SocialMediaBar />
            </Container>

            {/* Dialog for Apply Now */}
            <ApplicationFormDialog open={dialogOpen} handleDialogClose={handleDialogClose} courseInfo={course} />

            <FooterBar />
        </Box>
    );
};

export default AbroadCourseDescriptionPage;
