import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Container,
  Card,
  CardContent,
  Avatar,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { Buffer } from 'buffer';
import SocialMediaBar from '../SocialMedia/socialMedia';
import FooterBar from '../../layout/footer_bar/footer_bar_main';
import NepaliDate from 'nepali-date';  // Nepali date library

const bufferToBase64 = (buffer) => {
  if (!buffer) return '';
  return `data:image/jpeg;base64,${Buffer.from(buffer).toString('base64')}`;
};

function AlumniStudentWithRank({ studentRecord }) {
  const [view, setView] = useState('Study In Nepal'); // Default view set to 'Study In Nepal'
  const [filteredAlumni, setFilteredAlumni] = useState(studentRecord);
  const [selectedYear, setSelectedYear] = useState('');
  const [bsYears, setBsYears] = useState([]);
  const [visibleAlumniCount, setVisibleAlumniCount] = useState(12); // Initially show 12 alumni

  // Convert admission date to BS format
  const convertToBS = (date) => {
    if (!date || typeof date !== 'string' || !/^\d{4}-\d{2}-\d{2}$/.test(date)) {
      console.error('Invalid date format:', date);
      return 'Invalid Date'; // Return a fallback value
    }

    try {
      const nepaliDate = new NepaliDate(new Date(date)); // Convert the Gregorian date to BS
      return nepaliDate.format('YYYY-MM-DD'); // Returns BS formatted date
    } catch (error) {
      console.error('Error converting date to BS:', error);
      return 'Invalid Date'; // Return a fallback value
    }
  };

  // Extract BS year from the date (used for filtering)
  const extractBSYear = (date) => {
    const bsDate = convertToBS(date);
    return bsDate === 'Invalid Date' ? 'N/A' : bsDate.split('-')[0]; // Return only the year part of the BS date
  };

  // Get distinct BS years from the student records
  const getDistinctBSYears = () => {
    const years = studentRecord.map((alumni) => extractBSYear(alumni.admission_on));
    return [...new Set(years)];  // Get unique years
  };

  // Filter alumni by selected BS year
  const handleYearChange = (event) => {
    const selectedYear = event.target.value;
    setSelectedYear(selectedYear);

    const filtered = studentRecord.filter((alumni) => {
      const bsYear = extractBSYear(alumni.admission_on);
      return selectedYear === '' || bsYear === selectedYear; // Filter based on the selected BS year
    });

    setFilteredAlumni(filtered); // Update the filtered alumni
  };

  // Render alumni cards with additional details (directly displayed in the card now)
  const renderAlumniCards = (alumniList) => (
    <Grid container spacing={4} justifyContent="center" alignItems="center">
      {alumniList
        .filter((alumni) => alumni.alumini === 'Yes')
        .slice(0, visibleAlumniCount).map((alumni) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={alumni.sid}>
            <Card
              sx={{
                cursor: 'pointer',
                transition: 'transform 0.3s',
                '&:hover': { transform: 'scale(1.05)' },
                maxWidth: { xs: 280, sm: 300, md: 320, lg: 300 },
                display: 'flex',
               
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
               
                // padding: { xs: '8px', sm: '10px',md:'15px' },
                boxShadow: 3,
                height: 'auto', 
                borderRadius: 2,
                textAlign: 'center',
                marginTop:'35px',
                marginLeft: { xs: 'auto', sm: '20px', md: '10px' }, 
                marginRight: { xs: 'auto', sm: '20px', md: '10px' },
                // marginLeft: { xs: '20px', sm: '20px',sm:'10px' },
              }}
            >
              <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
                <Avatar
                  src={alumni.s_photo && alumni.s_photo.data ? bufferToBase64(alumni.s_photo.data) : ''}
                  alt={alumni.s_name}
                  sx={{
                    width: { xs: 55, sm: 60, md: 80 },
                    height: { xs: 57, sm: 65, md: 80 },
                    mb: 2,
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 'bold',
                    // marginBottom: { xs: '8px', sm: '12px' },
                    fontSize: { xs: '0.8rem', sm: '0.9rem', md:'1.01rem' },
                  }}
                >
                  {alumni.s_name}
                </Typography>

                {/* Display the Program */}
                {alumni.program && (
                  <Typography variant="body2" sx={{ fontSize: { xs: '0.8rem', sm: '0.95rem', }, color: 'gray', mt: 1 }}>
                    Program: {alumni.program}
                  </Typography>
                )}

                {/* Additional Alumni Information */}
                {alumni.graduated_college && (
                  <Typography variant="body2" sx={{ fontSize: { xs: '0.8rem', sm: '0.95rem'  }, color: 'gray', mt: 1 }}>
                    Graduated College: {alumni.graduated_college}
                  </Typography>
                )}

                {alumni.admitted_college && (
                  <Typography variant="body2" sx={{ fontSize: { xs: '0.8rem', sm: '0.95rem' }, color: 'gray', mt: 1 }}>
                    Admitted College: {alumni.admitted_college}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
    </Grid>
  );

  // Load more alumni when the button is clicked
  
  const loadMoreAlumni = () => {
    setVisibleAlumniCount((prevCount) => prevCount + 12); // Show 12 more alumni
  };

  useEffect(() => {
    if (studentRecord && studentRecord.length > 0) {
      console.log('Fetched Student Record:', studentRecord);
      setBsYears(getDistinctBSYears());
    } else {
      console.error('Student record is empty or undefined');
    }
  }, [studentRecord]);

  return (
    <Box sx={{ background: 'linear-gradient(135deg, #f0f0f0, #dcdcdc)', minHeight: '100vh', color: 'black', display: 'flex', flexDirection: 'column' }}>
      {/* Container that wraps the content */}
      <Container maxWidth="xl" sx={{ flex: 1, paddingTop: '4rem', paddingBottom: '3rem' }}>
        <Box sx={{ textAlign: 'center', mb: 4, }}>
          <Button
            variant={view === 'Study In Nepal' ? 'contained' : 'outlined'}
            onClick={() => setView('Study In Nepal')}
            sx={{
              fontSize: { xs: '0.75rem', sm: '1rem' },
              padding: { xs: '8px 16px', sm: '10px 20px' },
              margin: { xs: '5px', sm: '10px' },
              
            }}
          >
            Study In Nepal
          </Button>
          <Button
            variant={view === 'Study Abroad' ? 'contained' : 'outlined'}
            onClick={() => setView('Study Abroad')}
            sx={{
              fontSize: { xs: '0.75rem', sm: '1rem' },
              padding: { xs: '8px 16px', sm: '10px 20px' },
              margin: { xs: '5px', sm: '10px' },
            }}
          >
            Study Abroad
          </Button>
        </Box>

        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          align="center"
          sx={{
            fontWeight: 'bold',
            color: '#003366',
            mt: { xs: 4, md: 6 },
            fontSize: { xs: '1.2rem', sm: '2rem', md: '2.5rem' },
            fontFamily: 'Roboto-Serif',
            textTransform: 'uppercase',
          }}
        >
          Alumni of {view}
        </Typography>

        {/* BS Year Dropdown */}
        <FormControl sx={{ minWidth: 120 , width: { xs: '100%', sm:'10%'},marginTop:'17px', marginLeft:{sm:'7rem', md:'12rem'},  }} size="small">
          <InputLabel>Year</InputLabel>
          <Select value={selectedYear} onChange={handleYearChange} label="Year">
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            {bsYears.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Filtered alumni list based on the selected view */}
        {view === 'Study In Nepal' && renderAlumniCards(filteredAlumni.filter((alumni) => alumni.destination === 'Study Nepal'))}
        {view === 'Study Abroad' && renderAlumniCards(filteredAlumni.filter((alumni) => alumni.destination === 'Study Abroad'))}

        {/* Load More Button */}
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Button
            variant="contained"
            onClick={loadMoreAlumni}
            sx={{  fontSize: { xs: '0.75rem', sm: '1rem' },
            // marginTop:'17px',
            padding: { xs: '8px 16px', sm: '10px 20px' },
            margin: { xs: '5px', sm: '10px' }, }}
          >
            Load More
          </Button>
        </Box>
      </Container>

      <FooterBar />
      <SocialMediaBar />
    </Box>
  );
}

export default AlumniStudentWithRank;
