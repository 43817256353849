// import React, { useState } from 'react';
// import {
//   Box,
//   Typography,
//   Container,
//   Card,
//   CardContent,
//   Button,
//   Accordion,
//   AccordionSummary,
//   AccordionDetails,
//   Stack,
// } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import FooterBar from '../../layout/footer_bar/footer_bar_main';
// import ApplicationFormDialog from '../Courses/courseform';
// import { useLocation } from 'react-router';
// import SocialMediaBar from '../SocialMedia/socialMedia';

// const NepaliCoursesDescriptionPage = () => {
//   const [dialogOpen, setDialogOpen] = useState(false);

//   const location = useLocation();
//   const coursesReceived = location.state || {}; // Default to empty object if no state is passed

//   // Safely handle undefined courseTitle before calling .split()
//   const courseTitle = coursesReceived.courseTitle || ''; // Fallback to empty string if undefined
//   const preparation = courseTitle ? courseTitle.split('(')[0].split(' ')[0] : ''; // Safe split

//   const courseInfo = {
//     courseType: 'Study In Nepal', // Default value if courseType is not available
//     preparation: preparation || '', // Default empty string if preparation is undefined
//   };

//   const handleDialogOpen = () => setDialogOpen(true);
//   const handleDialogClose = () => setDialogOpen(false);

//   // Safe fallback for undefined arrays
//   const eligibility = Array.isArray(coursesReceived.eligibility) ? coursesReceived.eligibility : [];
//   const examPattern = Array.isArray(coursesReceived.examPattern) ? coursesReceived.examPattern : [];
//   const syllabus = Array.isArray(coursesReceived.syllabus) ? coursesReceived.syllabus : [];
//   const preparationTips = Array.isArray(coursesReceived.preparationTips) ? coursesReceived.preparationTips : [];
//   const faq = Array.isArray(coursesReceived.faq) ? coursesReceived.faq : [];

//   return (
//     <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
//       <Container maxWidth="lg" sx={{ flex: 1, py: 4 }}>
//         {/* Course Title */}
//         <Typography variant="h3" component="h1" gutterBottom align="center" sx={{
//           fontWeight: 'bold',
//           color: '#003366',
//           fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' }, // Responsive font size
//           mt: 4
//         }}>
//           {coursesReceived.courseTitle}
//         </Typography>

//         {/* Overview */}
//         <Typography variant="body1" paragraph sx={{
//           fontSize: { xs: '0.875rem', sm: '1rem', md: '1.125rem' } // Responsive font size
//         }}>
//           {coursesReceived.overview}
//         </Typography>

//         {/* Eligibility */}
//         <Typography variant="h4" component="h2" gutterBottom sx={{
//           fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2rem' } // Responsive font size
//         }}>
//           Eligibility
//         </Typography>
//         <ul>
//           {eligibility.map((item, index) => (
//             <li key={index}>
//               <Typography variant="body1" sx={{
//                 fontSize: { xs: '0.875rem', sm: '1rem', md: '1.125rem' } // Responsive font size
//               }}>
//                 {item}
//               </Typography>
//             </li>
//           ))}
//         </ul>

//         {/* Exam Pattern */}
//         <Typography variant="h4" component="h2" gutterBottom sx={{
//           fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2rem' }
//         }}>
//           Exam Pattern
//         </Typography>
//         <Stack direction="column" spacing={3} flexWrap="wrap">
//           {examPattern.map((pattern, index) => (
//             <Card sx={{ width:'100%', boxShadow: 3 }} key={index}>
//               <CardContent>
//                 <Typography variant="h6" sx={{
//                   fontSize: { xs: '1rem', sm: '1.125rem', md: '1.25rem' },
//                   fontWeight: 'bold'
//                 }}>
//                   {pattern.topic}
//                 </Typography>
//                 <Typography variant="body2" sx={{
//                   fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' },
//                   marginTop: '1rem'
//                 }}>
//                   {pattern.description}
//                 </Typography>
//               </CardContent>
//             </Card>
//           ))}
//         </Stack>

//         {/* Syllabus */}
//         <Typography variant="h4" component="h2" gutterBottom sx={{
//           fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2rem' },
//           marginTop: '1.25rem'
//         }}>
//           Syllabus
//         </Typography>
//         {syllabus.map((subject, index) => (
//           <Accordion key={index}>
//             <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}a-content`} id={`panel${index}a-header`}>
//               <Typography variant="body1" fontWeight="550" sx={{
//                 fontSize: { xs: '0.875rem', sm: '1rem', md: '1.125rem' } // Responsive font size
//               }}>
//                 {subject.topic}
//               </Typography>
//             </AccordionSummary>
//             <AccordionDetails sx={{ padding: '0px', margin: '0px' }}>
//               <ul style={{ padding: 0, margin: '0px 0px 10px 30px' }}>
//                 {subject.subtopics.map((subTopic, idx) => (
//                   <li key={idx}>
//                     <Typography variant="body2" sx={{
//                       fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' } // Responsive font size
//                     }}>
//                       {subTopic}
//                     </Typography>
//                   </li>
//                 ))}
//               </ul>
//             </AccordionDetails>
//           </Accordion>
//         ))}

//         {/* Preparation Tips */}
//         <Typography variant="h4" component="h2" gutterBottom sx={{
//           fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2rem' },
//           marginTop: '1.25rem'
//         }}>
//           Preparation Tips
//         </Typography>
//         <ul>
//           {preparationTips.map((tip, index) => (
//             <li key={index}>
//               <Typography variant="body1" sx={{
//                 fontSize: { xs: '0.875rem', sm: '1rem', md: '1.125rem' } // Responsive font size
//               }}>
//                 {tip}
//               </Typography>
//             </li>
//           ))}
//         </ul>

//         {/* Frequently Asked Questions */}
//         <Typography variant="h4" component="h2" gutterBottom sx={{
//           fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2rem' },
//           marginTop: '1.25rem'
//         }}>
//           Frequently Asked Questions
//         </Typography>
//         {faq.map((faqItem, index) => (
//           <Accordion key={index}>
//             <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}b-content`} id={`panel${index}b-header`}>
//               <Typography fontWeight="550" sx={{
//                 fontSize: { xs: '0.875rem', sm: '1rem', md: '1.125rem' } // Responsive font size
//               }}>
//                 {faqItem.question}
//               </Typography>
//             </AccordionSummary>
//             <AccordionDetails>
//               <Typography sx={{
//                 fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' } // Responsive font size
//               }}>
//                 {faqItem.answer}
//               </Typography>
//             </AccordionDetails>
//           </Accordion>
//         ))}

//         {/* Apply Now and Contact Us Buttons */}
//         <Box sx={{ textAlign: 'center', mt: 4 }}>
//           <Button variant="contained" onClick={handleDialogOpen} sx={{
//             mr: 2,
//             backgroundImage: 'linear-gradient(90deg, #49BBBD 0%, #136CB5 100%)',
//             color: 'white',
//             '&:hover': { backgroundImage: 'linear-gradient(90deg, #136CB5 0%, #49BBBD 100%)' },
//             fontSize: { xs: '0.875rem', sm: '1rem', md: '1.125rem' } // Responsive font size
//           }}>
//             Apply Now
//           </Button>
//           <Button variant="outlined" color="secondary" href="/contact" sx={{
//             fontSize: { xs: '0.875rem', sm: '1rem', md: '1.125rem' } // Responsive font size
//           }}>
//             Contact Us
//           </Button>
//         </Box>
//       </Container>

//       <SocialMediaBar />
//       <ApplicationFormDialog open={dialogOpen} handleDialogClose={handleDialogClose} courseInfo={courseInfo} />
//       <FooterBar />
//     </Box>
//   );
// };

// export default NepaliCoursesDescriptionPage;







import React, { useState } from 'react';
import { Box, Typography, Container, Card, CardContent, Button, Accordion, AccordionSummary, AccordionDetails, Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FooterBar from '../../layout/footer_bar/footer_bar_main';
import ApplicationFormDialog from '../Courses/courseform';
import { useLocation } from 'react-router';
import SocialMediaBar from '../SocialMedia/socialMedia';

// NepaliCoursesDescriptionPage component
const NepaliCoursesDescriptionPage = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const location = useLocation();
  const coursesReceived = location.state || {}; // Default to empty object if no state is passed

  const courseTitle = coursesReceived.courseTitle || ''; // Fallback to empty string if undefined
  const preparation = courseTitle ? courseTitle.split('(')[0].split(' ')[0] : ''; // Safe split

  const courseInfo = {
    courseType: 'Study In Nepal', // Default value if courseType is not available
    preparation: preparation || '', // Default empty string if preparation is undefined
  };

  // Safe fallback for undefined arrays
  const eligibility = Array.isArray(coursesReceived.eligibility) ? coursesReceived.eligibility : [];
  const examPattern = Array.isArray(coursesReceived.examPattern) ? coursesReceived.examPattern : [];
  const syllabus = Array.isArray(coursesReceived.syllabus) ? coursesReceived.syllabus : [];
  const preparationTips = Array.isArray(coursesReceived.preparationTips) ? coursesReceived.preparationTips : [];
  const faq = Array.isArray(coursesReceived.faq) ? coursesReceived.faq : [];

  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Container maxWidth="lg" sx={{ flex: 1, py: 4 }}>
        <Typography variant="h3" component="h1" gutterBottom align="center" sx={{
          fontWeight: 'bold',
          color: '#003366',
          fontSize: { xs: '1.2rem', sm: '2rem', md: '2.5rem' }, 
          mt:{xs:1,sm:2,md:4},
          fontFamily:"Roboto-Serif"
        }}>
          {coursesReceived.courseTitle}
        </Typography>

        <Typography variant="body1" component='p' gutterBottom sx={{
          fontSize: { xs: '0.875rem', sm: '1rem', md: '1.25rem' },
          fontFamily:"poppins",
          textAlign: "justify",
          mb:1
        }}>
          {coursesReceived.overview}
        </Typography>

        <Typography variant="h4" component="h2"  
          sx={{
            fontSize: { xs: '1.2rem', sm: '1.75rem', md: '2rem' },
            fontWeight:"bold",
            fontFamily:"Roboto-Serif",
            mt:2
            // backgroundColor:"green"
          }}>
          Eligibility
        </Typography>
        <ul
          style={{
            // backgroundColor:"red",
            marginTop:"0px"
          }}
        >
          {eligibility.map((item, index) => (
            <li key={index}
              style={{
                // backgroundColor:"greenyellow",
                marginLeft:"-18px"
              }}
            >
              <Typography variant="body1" sx={{
                fontSize: { xs: '0.875rem', sm: '1rem', md: '1.125rem' },
                fontFamily:"poppins"
              }}>
                {item}
              </Typography>
            </li>
          ))}
        </ul>

        <Typography variant="h4" component="h2" gutterBottom 
         sx={{
          fontSize: { xs: '1.2rem', sm: '1.75rem', md: '2rem' },
          fontWeight:"bold",
          fontFamily:"Roboto-Serif",
          mt:2
          // backgroundColor:"green"
        }}>
          Exam Pattern
        </Typography>
        <Stack direction="column" spacing={1} flexWrap="wrap">
          {examPattern.map((pattern, index) => (
            <Card sx={{ width:'100%' }} key={index}>
              <CardContent>
                <Typography variant="h6" sx={{
                  fontSize: { xs: '0.9rem', sm: '1.125rem', md: '1.25rem' },
                  fontWeight: 'bold',
                  fontFamily:"poppins"
                }}>
                  {pattern.topic}
                </Typography>
                <Typography variant="body2" sx={{
                  fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' },
                  // marginTop: '1rem',
                  fontFamily: "poppins"
                }}>
                  {pattern.description}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Stack>

        <Typography variant="h4" component="h2" gutterBottom 
        sx={{
          fontSize: { xs: '1.2rem', sm: '1.75rem', md: '2rem' },
          fontWeight:"bold",
          fontFamily:"Roboto-Serif",
          mt:3
          // backgroundColor:"green"
        }}>
          Syllabus
        </Typography>
        {syllabus.map((subject, index) => (
          <Accordion key={index}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}a-content`} id={`panel${index}a-header`}>
              <Typography variant="body1" fontWeight="bold" sx={{
                fontSize: { xs: '0.875rem', sm: '1.125rem', md: '1.25rem' },
                fontFamily:"popoins"
              }}>
                {subject.topic}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '0px', margin: '0px' }}>
              <ul style={{ padding: 0, margin: '0px 0px 10px 30px' }}>
                {subject.subtopics.map((subTopic, idx) => (
                  <li key={idx}>
                    <Typography variant="body2" sx={{
                      fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' },
                      fontFamily:"poppins"
                    }}>
                      {subTopic}
                    </Typography>
                  </li>
                ))}
              </ul>
            </AccordionDetails>
          </Accordion>
        ))}

        <Typography variant="h4"  
        sx={{
          fontSize: { xs: '1.2rem', sm: '1.75rem', md: '2rem' },
          fontWeight:"bold",
          fontFamily:"Roboto-Serif",
          mt:3
          // backgroundColor:"green"
        }}>
          Preparation Tips
        </Typography>
        <ul>
          {preparationTips.map((tip, index) => (
            <li key={index}
              style={{
                // backgroundColor:"greenyellow",
                marginLeft:"-18px"
              }}
            >
              <Typography variant="body1" sx={{
                fontSize: { xs: '0.875rem', sm: '1rem', md: '1.125rem' },
                fontFamily: "poppins"
              }}>
                {tip}
              </Typography>
            </li>
          ))}
        </ul>

        <Typography variant="h4" component="h2" gutterBottom 
        sx={{
          fontSize: { xs: '1.2rem', sm: '1.75rem', md: '2rem' },
          fontWeight:"bold",
          fontFamily:"Roboto-Serif",
          mt:3
          // backgroundColor:"green"
        }}>
          Frequently Asked Questions
        </Typography>
        {faq.map((faqItem, index) => (
          <Accordion key={index}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}b-content`} id={`panel${index}b-header`}>
              <Typography fontWeight="bold" sx={{
                fontSize: { xs: '0.875rem', sm: '1.125rem', md: '1.25rem' },
                fontFamily:"poppins"
              }}>
                {faqItem.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{
                fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' },
                fontFamily:"poppins"
              }}>
                {faqItem.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}

        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Button variant="contained" onClick={handleDialogOpen} sx={{
            mr: 2,
            backgroundImage: 'linear-gradient(90deg, #49BBBD 0%, #136CB5 100%)',
            color: 'white',
            '&:hover': { backgroundImage: 'linear-gradient(90deg, #136CB5 0%, #49BBBD 100%)' },
            fontSize: { xs: '0.875rem', sm: '1rem', md: '1.125rem' }
          }}>
            Apply Now
          </Button>
          <Button variant="outlined" color="secondary" href="/contact" sx={{
            fontSize: { xs: '0.875rem', sm: '1rem', md: '1.125rem' }
          }}>
            Contact Us
          </Button>
        </Box>
      </Container>

      <SocialMediaBar />
      <ApplicationFormDialog open={dialogOpen} handleDialogClose={handleDialogClose} courseInfo={courseInfo} />
      <FooterBar />
    </Box>
  );
};

export default NepaliCoursesDescriptionPage;