import React from 'react';
import { Box, Typography } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const HeaderInfo = () => {
    return (
        <Box sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: { xs: "5px", sm: "15px" },
            justifyContent: "center",
            alignItems: "center",
            // gap: '15px',
            padding: { xs: "5px", sm: "8px", md: "10px" },
            // backgroundColor: "#136CB5", 
            color: '#fff',
            textAlign: "center",
            // fontSize: "14px",
            // "@media (max-width: 300px)": {
            //     flexDirection: "row",
            //     // gap: "8px"
            // }
        }}>
            {/* Address Section */}
            <Typography variant="body2" fontFamily="Roboto-Serif"
                sx={{ display: 'flex', alignItems: 'center',  fontSize: { xs: '12px', sm: '14px', md: '16px', lg: '18px' }, }}>
                <LocationOnIcon sx={{  fontWeight: 'bold', marginRight: '5px',  fontSize: { xs: '12px', sm: '14px', md: '16px', lg: '18px' }, }} />
                Putalisadak-Kathmandu
            </Typography>

            {/* Phone Section */}
            <Typography variant="body2" fontFamily="Roboto-Serif"
                sx={{ display: 'flex', alignItems: 'center',  fontSize: { xs: '12px', sm: '14px', md: '16px', lg: '18px' }, }}>
                <PhoneIcon sx={{ fontWeight: 'bold', marginRight: '5px',  fontSize: { xs: '12px', sm: '14px', md: '16px', lg: '18px' }, }} />
                <a href="tel:9841354464" style={{ color: '#fff', textDecoration: 'none', fontWeight: 'bold' }}>
                    9841354464  |
                </a>
            </Typography>

            {/* Email Section */}
            <Typography variant="body2" fontFamily="Roboto-Serif"
                sx={{ display: 'flex', alignItems: 'center',  fontSize: { xs: '12px', sm: '14px', md: '16px', lg: '18px' }, }}>
                <MailIcon sx={{ fontWeight: 'bold', marginRight: '5px',  fontSize: { xs: '12px', sm: '14px', md: '16px', lg: '18px' }, }} />
                <a href="mailto:education.com4@gmail.com" style={{ color: '#fff', textDecoration: 'none', fontWeight: 'bold' }}>
                    info@educationpvt.com
                </a>
            </Typography>
        </Box>
    );
};

export default HeaderInfo;
