
import React, { useState } from 'react';
import { Box, Container, Grid, Button } from '@mui/material';
import TestimonialCard from './testonomialCard'; 
import TestimonialsHeader from './testonomialHeader'; 
import './Testimonial.css';

const Testimonials = ({ studentTestimonial }) => {
  const [visibleCount, setVisibleCount] = useState(3); // Start with 3 testimonials visible

  const handleShowMore = () => {
    setVisibleCount(prevCount => prevCount + 3); // Increase count by 3
  };

  const handleShowLess = () => {
    setVisibleCount(3); // Reset count to 3
  };

  return (
    <Container>
      <Box className="testimonials-container">
        {/* Header Component */}
        <TestimonialsHeader />

        {/* Testimonials Grid */}
        <Grid container spacing={5} justifyContent="center">
          {studentTestimonial.slice(0, visibleCount).map((testimonial, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <TestimonialCard testimonial={testimonial} /> {/* TestimonialCard component */}
            </Grid>
          ))}
        </Grid>

        {/* Show More / Show Less Button */}
        {visibleCount < studentTestimonial.length && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleShowMore}
            sx={{ mt: 2, backgroundColor: '#49BBBD', color: "#ffffff" }}
          >
            Show More
          </Button>
        )}

        {visibleCount >= studentTestimonial.length && visibleCount > 3 && (
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleShowLess}
            sx={{ mt: 2 }}
          >
            Show Less
          </Button>
        )}
      </Box>
    </Container>
  );
};

export default Testimonials;
